import styles from './error.module.css';
import { Panel, QuickLink, SidebarLayout, cn } from '@lhb/ui';
import { ErrorGrafik } from '../components/assets/error';
import { HttpStatus, simpleHash } from '@lhb/utils';
import { Error500 } from './content/500';
import { Error403 } from './content/403';
import { Error401 } from './content/401';
import { Error404 } from './content/404';

type ErrorPageProps = {
  status?: HttpStatus;
  link?: React.ElementType;
};

export function ErrorPage({ status, link }: ErrorPageProps) {
  const statusCode = Number(status);
  const ErrorComponent = getErrorComponent(statusCode);
  console.log('ErrorPage', { status });
  return (
    <SidebarLayout sidebar={<Sidebar status={statusCode} link={link} />}>
      <Panel variant="default" className={cn(styles['panel'])}>
        <ErrorComponent link={link} />
      </Panel>
    </SidebarLayout>
  );
}

function getErrorComponent(
  status: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
) {
  switch (status) {
    case HttpStatus.NOT_FOUND:
      return Error404;
    case HttpStatus.FORBIDDEN:
      return Error403;
    case HttpStatus.UNAUTHORIZED:
      return Error401;
    default:
      return Error500;
  }
}

function Sidebar({ link, status }: ErrorPageProps) {
  const links = [
    {
      url: 'https://landshypotek.se',
      urlText: 'Gå till landshypotek.se',
    },
    {
      url: 'https://internetbank.landshypotek.se',
      urlText: 'Logga in på internetbanken',
    },
  ];
  return status === HttpStatus.NOT_FOUND ? (
    <Panel variant="info">
      <h2>Snabblänkar</h2>
      {links.map(({ url, urlText }) => (
        <QuickLink
          to={url}
          as={link}
          key={simpleHash(JSON.stringify(url + urlText))}
        >
          {urlText}
        </QuickLink>
      ))}
    </Panel>
  ) : (
    <div aria-hidden className={cn(styles['container'])}>
      <ErrorGrafik style={{ width: '100%' }} />
    </div>
  );
}
