import { useEffect, useImperativeHandle, useRef } from 'react';

import styles from './dialog.module.css';

export function useDialog(
  dialogRef: React.ForwardedRef<HTMLDialogElement>,
  onClose?: React.MouseEventHandler<HTMLButtonElement>,
) {
  const innerRef = useRef<HTMLDialogElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(dialogRef, () => innerRef.current!, []);

  const close: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    innerRef.current?.close();
    if (typeof onClose === 'function') onClose(e);
  };

  useEffect(() => {
    if (!innerRef.current) return;
    const current = innerRef.current;
    const animationEndEvent = () => current.classList.remove(styles['closing']);
    current.addEventListener('animationend', animationEndEvent);

    const closeEventClass = () => current.classList.add(styles['closing']);
    current.addEventListener('close', closeEventClass);

    // event is type PointerEvent, but types doesn't think target.id exists, and can't be bound to addEventListener
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const closeOnOutsideClick: EventListener = (event: any) => {
      /* If the target is anything other than the dialog element,
      like an input element inside the dialog we don't want to do anything */
      if (event.target?.tagName !== current.tagName) return;

      // If the click wasn't inside the dialog element, we want to dismiss it.
      if (!checkIfInsideElement(event)) current.close();
    };
    current.addEventListener('click', closeOnOutsideClick);

    return () => {
      current.removeEventListener('animationend', animationEndEvent);
      current.removeEventListener('close', closeEventClass);
      current.removeEventListener('click', closeOnOutsideClick);
    };
  }, [innerRef]);

  return {
    close,
    innerRef,
  };
}

// when the dialog is open, all clicks on screen are considered to be in the dialog elment
// so we need to check if the click event was actually inside the bounding box of the element iself
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkIfInsideElement(event: any) {
  const rect = event.target?.getBoundingClientRect();
  const clickedInDialog =
    rect.top <= event.clientY &&
    event.clientY <= rect.top + rect.height &&
    rect.left <= event.clientX &&
    event.clientX <= rect.left + rect.width;

  return clickedInDialog;
}
