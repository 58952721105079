import { Case, convertCase } from '@lhb/utils';
import { BackLink } from '../../components/back-link';
import sharedStyles from '../shared.module.css';
import { useBankIdCheck } from './hooks/check.hook';
import { Button, cn, Akka } from '@lhb/ui';
import { useEffect, useMemo } from 'react';
import { json, Link, useLoaderData, useRevalidator } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BottomBlock, MessageBlock } from '../../components/blocks';
import { autoStart } from '../../components/auth-buttons/autostart';
import { appInsights } from '../../../appinsight';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApiError, BankIdService, InitResponse } from '@lhb/api/idp';

type LoaderResponse = InitResponse & { runAt: string };

export async function BankIdLocalLoader() {
  try {
    console.log('Initiating BankId process');
    appInsights?.trackTrace({
      severityLevel: SeverityLevel.Information,
      message: 'Initiate BankId process',
    });
    const init = await BankIdService.init({ sameDevice: true });

    return json({ ...init, runAt: new Date().toISOString() }, { status: 200 });
  } catch (e) {
    console.log('BankId Init error', e);
    appInsights?.trackException({
      error: e as Error,
      severityLevel: SeverityLevel.Error,
      properties: { loader: 'BankId Init error' },
    });
    if (e instanceof ApiError) {
      throw new Response(e.message, {
        status: e.status,
        statusText: e.statusText,
      });
    }

    throw new Response('Internal Error', {
      status: 500,
      statusText: e as string,
    });
  }
}

const THIRTY_SECONDS = 1000 * 30;

export function BankIdLocal() {
  const reval = useRevalidator();
  const { requestId, autoStartToken, runAt } =
    useLoaderData() as LoaderResponse;
  const { t } = useTranslation('BANKID_LOCAL');
  const { status, state } = useBankIdCheck(requestId);
  const notStarted: Array<typeof status> = [
    'OutstandingTransaction',
    'NoClient',
  ];

  const uLink = autoStart(autoStartToken, navigator.userAgent);

  useEffect(() => {
    // revalidate if the loader was run more than 30 seconds ago
    if (
      window.config?.autostart &&
      reval.state === 'idle' &&
      runAt &&
      new Date().getTime() - new Date(runAt).getTime() > THIRTY_SECONDS
    ) {
      reval.revalidate();
    }
  }, []);

  const isNotStarted = useMemo(
    () => status && notStarted.includes(status),
    [status],
  );

  useEffect(() => {
    if (window.config?.autostart && isNotStarted) window.location.href = uLink;
  }, [uLink]);

  if (state === 'failed') throw new Error(status);

  const statusMessageKey = useMemo(() => {
    if (window.config?.autostart && isNotStarted) {
      return `AUTOSTART_${convertCase(status, Case.Snake).toUpperCase()}`;
    }
    return convertCase(status, Case.Snake).toUpperCase();
  }, [status]);

  return (
    <>
      <h1 className={sharedStyles['heading']}>
        {t('LOGIN_INTERNETBANK_TITLE')}
      </h1>

      <Akka />

      <MessageBlock>
        {status && (
          <p className={cn('text-bold', sharedStyles['subtitle'])}>
            {t(statusMessageKey)}
          </p>
        )}
      </MessageBlock>
      <BottomBlock>
        {isNotStarted && autoStartToken && (
          <Button
            block
            asChild
            variant={uLink.startsWith('bankid://') ? 'secondary' : 'primary'}
          >
            <Link to={uLink} referrerPolicy="origin">
              {t('MANUAL_START')}
            </Link>
          </Button>
        )}

        <BackLink label={t('CANCEL', { ns: 'COMMON' })} />
      </BottomBlock>
    </>
  );
}
