import { SVGColor } from '../../../types/color';
import { ClassName, cn } from '../../utils';
import style from './symbol.module.css';

export type SvgSymbol = React.FunctionComponent<
  React.SVGAttributes<SVGElement>
>;

export type SymbolFactoryProps<Symbols> = {
  name: Symbols;
  size?: string | number;
  color?: SVGColor;
  className?: ClassName;
};

export function SymbolFactory<Symbols extends string>(
  symbolsMap: Record<Symbols, SvgSymbol>,
  deprecatedSymbolsMap: Record<Symbols, SvgSymbol>,
  defaultSize: string | number,
) {
  return function ({
    className,
    name,
    size = defaultSize,
    color = 'currentColor',
  }: SymbolFactoryProps<Symbols>) {
    if (name in deprecatedSymbolsMap)
      console.warn(`[Deprecated] Symbol '${name}' is deprecated`);
    const Comp: SvgSymbol = symbolsMap[name] || deprecatedSymbolsMap[name];
    if (!Comp) throw new Error(`${name} is not a valid icon/pictogram`);
    return (
      <Comp
        fill={color}
        height={size}
        className={cn(style['icon'], className)}
      />
    );
  };
}
