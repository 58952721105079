import { PropsWithChildren } from 'react';
import styles from './sidebar-layout.module.css';

type SidebarLayoutProps = PropsWithChildren<{
  sidebar?: React.ReactNode;
}>;
export function SidebarLayout({ children, sidebar }: SidebarLayoutProps) {
  if (!sidebar) return children;
  return (
    <div className={styles['container']}>
      {children && <main className={styles['content']}>{children}</main>}

      <aside className={styles['sidebar']}>{sidebar}</aside>
    </div>
  );
}
